.minigameBox.minigameBox--reward {
    position: relative;
    width: 230px;
    height: 230px;
    background-color: #ffe8c9;
}
.minigameBox--reward__slotMachine {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
}
.minigameBox--reward__slotMachine__top {
    position: absolute;
    width: 200px;
    height: 200px;
    background-image: url('../images/reward_box_top.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-x: 100%;
    z-index: 20;
}

.minigameBox--reward__slotMachine__bottom {
    position: absolute;
    width: 200px;
    height: 200px;
    background-image: url('../images/reward_box_bottom.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-x: 100%;
    z-index: 7;
}
.minigameBox--reward__slotMachine__cover {
    position: absolute;
    top: 15px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #ffe8c9;
    z-index: 3;
}

.minigameBox--reward__slotMachine__coin {
    position: absolute;
    top: 116px;
    left: 99px;
    width: 40px;
    height: 40px;
    background-image: url('../images/coin_sprites.png');
    background-size: 800%;
    background-repeat: no-repeat;
    background-position-x: 0%;
    opacity: 0;
    z-index: 10;
}
.minigameBox--reward__slotMachine__coin.minigameBox--reward__slotMachine__coin--animate {
    opacity: 1;
    animation-name: coinSlotInsertAnim;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
@keyframes coinSlotInsertAnim {
    to {
        transform: translateX(-40px);
    }
}
.minigameBox--reward__slotMachine__paper {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 155px;
    height: 130px;
    background-color: #f3ddaf;
    border: 3px solid #c6b082;
    font-family: silkscreenRegular;
    text-align: center;
    font-size: 14px;
    z-index: 1;
}
.minigameBox--reward__slotMachine__paper__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.minigameBox--reward__slotMachine__paper.minigameBox--reward__slotMachine__paper--animate {
    animation-name: showPaperAnim;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes showPaperAnim {
    0% {
        transform: translate(-50%, 0px);
    }
    20% {
        transform: translate(-50%, 0px);
    }
    21% {
        transform: translate(-50%, -30px);
    }
    40% {
        transform: translate(-50%, -30px);
    }
    41% {
        transform: translate(-50%, -60px);
    }
    60% {
        transform: translate(-50%, -60px);
    }
    61% {
        transform: translate(-50%, -90px);
    }
    80% {
        transform: translate(-50%, -90px);
    }
    81% {
        transform: translate(-50%, -120px);
    }
    100% {
        transform: translate(-50%, -120px);
    }
}


.minigameBox--reward__slotMachine__insertButton {
    position: absolute;
    top: 157px;
    left: 77px;
    width: 50px;
    font-family: silkscreenRegular;
    text-align: center;
    z-index: 30;
    font-size: 8px;
    text-align: center;
    color: #fff;
    background-color: #f63a1d;
    box-shadow: -2px 2px 0px 0px rgba(0,0,0,0.3);
    border-radius: 2px;
    user-select: none;
    border: 1px solid #c02c15;
    cursor: pointer;
}
.minigameBox--reward__slotMachine__insertButton:active {
    transform: translate(-2px, 2px);
    box-shadow: none;
}
.minigameBox--reward__slotMachine__insertButton.minigameBox--reward__slotMachine__insertButton--disabled {
    pointer-events: none;
    transform: translate(-2px, 2px);
    box-shadow: none;
}

.minigameBox--reward__cost {
    position: absolute;
    top: 140px;
    left: 15px;
    z-index: 100;
    text-align: center;
    font-family: silkscreenRegular;
}
.minigameBox--reward__cost__container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.minigameBox--reward__cost__icon {
    width: 20px;
    height: 20px;
    background-image: url('../images/coin_sprites.png');
    background-size: 800%;
    background-repeat: no-repeat;
    background-position-x: 0%;   
}