.minigameBox--petCat {
    width: 225px;
    min-width: 225px;
    height: 225px;
    /* border-right: 1px solid #000;
    border-left: 1px solid #000; */
}
.minigameBox--petCat__message {
  position: absolute;
  top: 0px;
  left: 60px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 10px;
  font-family: silkscreenRegular;
}
.minigameBox--petCat__message.disabled {
  display: none;
}
  
  .minigameBox__cat {
    position: absolute;
    top: 13px;
    left: 12px;
    width: 200px;
    height: 200px;
    background-image: url('../images/cat_sprites.png');
    background-size: 400%;
    background-repeat: no-repeat;
    background-position-x: 0%;
    user-select: none;
    cursor: grab;
  }
  .minigameBox__cat--petting {
    background-position-x: 33.33%;
    animation-name: pettingAnim;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .minigameBox__cat--angry {
    background-position-x: 66.66%;
  }
  .minigameBox__cat--furious {
    background-position-x: 100%;
    animation-name: shakingAnim;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

  }
  .minigameBox__cat--end {
    background-position-x: 100%;
    filter: hue-rotate(309deg);
  }
  
  @keyframes pettingAnim {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
        transform: rotate(5deg);
      }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes shakingAnim {
    0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
  }