.minigameBox--sleep {
    width: 225px;
    height: 225px;
}
.minigameBox--sleep__bed {
    position: absolute;
    top: 20px;
    left: 12px;
    width: 200px;
    height: 200px;
    background-image: url('../images/parham_sleep_sprites.png');
    background-size: 200%;
    background-repeat: no-repeat;
    background-position-x: 100%;
}
.minigameBox--sleep__bed.off {
    background-position-x: 0%;
}
.minigameBox--sleep__toggleLampButton {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 3px;
    background-color: #ccc69a;
    line-height: 30px;
    text-align: center;
    font-size: 18px;
    color: #000;
    border-radius: 3px;
    box-shadow: 0px 2px 3px #000;
    user-select: none;
    font-family: silkscreenRegular;
}
.minigameBox--sleep__toggleLampButton:active {
    box-shadow: none;
    transform: translate(-50%, 1px);
    filter: brightness(75%);
}

.minigameBox--sleep__lightSwitch {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 50px;
    border: 2px solid #000;
    box-sizing: border-box;
    box-shadow: 1px -1px 1px rgba(0,0,0,0.3) inset;
    background-color: #efefef;
    cursor: pointer;
}
.minigameBox--sleep__lightSwitch:active {
    filter: brightness(75%);
}
.minigameBox--sleep__lightSwitch__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 20px;
    border: 2px solid #000;
    box-sizing: border-box;
    background-color: #fff;
}
.minigameBox--sleep__lightSwitch__switch {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 6px;
    height: 6px;
    background-color: #000;
    box-sizing: border-box;
}
.minigameBox--sleep__lightSwitch.off .minigameBox--sleep__lightSwitch__switch {
    top: 9px;
}