.minigameBox--watchTV {
    width: 400px;
    height: 225px;
  }
  .minigameBox__parhamCouch {
    position: absolute;
    top: 9px;
    left: 0px;
    width: 200px;
    height: 200px;
    background-image: url('../images/parham_couch_sprites.png');
    background-size: 200%;
    background-repeat: no-repeat;
    background-position-x: 100%;
  }
  .minigameBox__parhamCouch--ad {
    background-position-x: 0%;
  }
  .minigameBox__tv {
    position: absolute;
    top: 17px;
    left: 200px;
    width: 185px;
    height: 185px;
  }
  .minigameBox__tv__image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url('../images/tv.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-x: 0%;
    z-index: 5;
  }
  .minigameBox__tv__channel {
    position: absolute;
    top: 28px;
    left: 33px;
    width: 87px;
    height: 66px;
    background-image: url('../images/tv_channels.png');
    background-size: 1200%;
    background-repeat: no-repeat;
    background-position-x: 0%;
    transform: perspective(400px) rotateY(-30deg);
    z-index: 1;
  }
  .minigameBox__tv__static {
    display: none;
    position: absolute;
    top: 28px;
    left: 33px;
    width: 85px;
    height: 63px;
    background-image: url('../images/tv_static.gif');
    background-size: 100%;
    background-repeat: no-repeat;
    transform: perspective(400px) rotateY(-30deg);
    z-index: 1;
  }
  .minigameBox__tv__static.minigameBox__tv__static--active {
    display: block;
  }
  
  .minigameBox--watchTV__tvButton {
    position: absolute;
    left: 264px;
    top: 168px;
    padding: 0px 5px 0px 5px;
    width: 40px;
    height: 40px;
    background-color: #639bff;
    line-height: 30px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    border-radius: 3px;
    box-shadow: 0px 3px 1px #4368ab;
    user-select: none;
    font-family: silkscreenRegular;
    line-height: 40px;
    cursor: pointer;
    z-index: 10;
  }
  .minigameBox--watchTV__tvButton:active {
    box-shadow: none;
    transform: translateY(3px);
    filter: brightness(75%);
  }