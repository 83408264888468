@font-face {
  font-family: silkscreenRegular;
  src: url(./fonts/Silkscreen-Regular.ttf);
}
html,
body {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: 'Roboto', sans-serif;
  background-color: #000;
}

html *,
html *::before,
html *::after {
  box-sizing: border-box;
}

.App {
  position: relative;
  font-size: 16px;
  background-color: #ffe8c9;
}

.landing {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #ffc068;
  background-image: linear-gradient(#ffc068, 70%, #ffe8c9);
}
.landing__center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.landing__headerContainer {
  margin: 0 auto;
  color: #ff9500;
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 2px 0px #000;
  font-family: silkscreenRegular;
}
.landing__headerTop {
  font-size: 60px;
}
.landing__lineDivider {
  width: 310px;
  height: 2px;
  background-color: #ff9500;
  margin: 0 auto;
  border-radius: 4px;
}
.landing__headerBottom {
  font-size: 30px;
  text-shadow: 1px 1px 0px #000;
}

.landing__bioContainer {
  border-radius: 4px;
  padding: 15px;
  background-color: #ffe8c9;
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 960px;
  color: #000;
  text-shadow: 0px 0px 2px #fff;
}
.landing__bioIcon {
  width: 100px;
  min-width: 100px;
  height: 100px;
  margin-right: 15px;
  background-color: #009dff;
  background-image: url('../src/images/pixel_parham_thumb_transparent.png');
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  border: 2px solid #000;
  box-shadow: 5px 5px rgba(0,0,0,0.2) inset;
}
.landing__bioIcon.landing__bioIcon--christmas {
  background-image: url('../src/images/pixel_parham_christmas.png');
}
a {
  all: unset;
  cursor: pointer;
}
.landing__socialContainer {
  position: fixed;
  left: 15px;
  bottom: 15px;
  font-size: 24px;
  margin-top: 15px;
  color: #fff;
  text-align: center;
}
.landing__socialIcon {
  color: #ff9500;
  margin: 0 auto;
  text-shadow: 1px 1px 1px black;
}
.landing__socialIcon:not(:last-child) {
  margin-bottom: 10px;
}
.landing__socialIcon:hover {
  filter: brightness(120%);
}

.landing__games {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
}

.content {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  padding: 15px;
}

.mainHeader {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}
.App.darkMode .mainHeader {
  color: #fff;
}

.coin {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  z-index: 100;
}
.coin .coin__icon {
  width: 60px;
  height: 60px;
  background-image: url('./images/coin_sprites.png');
  background-size: 800%;
  background-repeat: no-repeat;
  background-position-x: 0%;
  animation-name: coinSpinAnim;
  animation-duration: 1s;
  animation-timing-function: steps(7);
  animation-iteration-count: infinite;
}
@keyframes coinSpinAnim {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 100%;
  }
}
.coin .coin__qty {
  text-align: center;
  font-size: 22px;
  color: #fff;
  text-shadow: 0px 0px 1px #000;
  font-family: silkscreenRegular;
  font-weight: bold;
  margin-top: -5px;
}

.bio {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
.bio .bio__icon {
  background-image: url('../src/images/pixel_parham_thumb.png');
  background-size: 100%;
  background-repeat: no-repeat;
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.3) inset;
  margin-right: 10px;
}
.gamesContainer {
  position: relative;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.minigameBox {
  position: relative;
  padding: 10px;
}
.minigameBox:not(:last-child) {
  margin-right: 10px;
}
.minigameBox__caption {
  position: absolute;
  bottom: 5px;
  text-align: center;
  width: 100%;
}

.coinPopup {
  position: absolute;
  top: -15px;
  right: 55px;
  z-index: 20;
  animation-name: coinGainAnim;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  user-select: none;
}
.coinPopup__icon {
  width: 40px;
  height: 40px;
  background-image: url('./images/coin_sprites.png');
  background-size: 800%;
  background-repeat: no-repeat;
  background-position-x: 0%;
  margin: 0 auto;
}
.coinPopup__val {
  text-align: center;
  font-size: 22px;
  color: #fff;
  text-shadow: 0px 0px 1px #000;
  font-family: silkscreenRegular;
  font-weight: bold;
}
@keyframes coinGainAnim {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}

.infoSection:not(:last-child) {
  margin-bottom: 20px;
}

.infoSection__header {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: #ff9500;
  text-shadow: 1px 1px 0px #000;
  font-family: silkscreenRegular;
}
.infoSection__header__icon {
  width: 50px;
  height: 50px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 5px;
}
.infoSection--experience .infoSection__header__icon {
  background-image: url('../src/images/windows_icon.png');
}
.infoSection--education .infoSection__header__icon {
  background-image: url('../src/images/grad_hat_icon.png');
}
.infoSection--other .infoSection__header__icon {
  background-image: url('../src/images/soccer_ball_icon.png');
}
.infoSection--certificates .infoSection__header__icon {
  background-image: url('../src/images/certificate_icon.png');
}
.infoSection__body {
  border-radius: 4px;
  background-color: #ffe8c9;
  padding: 15px;
  padding-top: 5px;
}
.infoSection__body__container {
  border-radius: 4px;
}
.infoSection__body__container:not(:last-child) {
  margin-bottom: 15px;
}
.infoSection__body__title {
  font-weight: bold;
  font-size: 20px;
  border-radius: 4px 4px 0px 0px;
  color: #000;
  text-shadow: 0px 0px 2px #fff;
  margin-bottom: 5px;
}
.infoSection__body__subtitle {
  font-weight: bold;
  padding: 5px;
}
.infoSection__body__subtitle span {
  font-weight: bold;
}
.infoSection__ul {
  all: unset;
  display: block;
  border-radius: 0px 0px 4px 4px;
  margin-top: 0px;
  padding-left: 5px;
}
.infoSection__ul:not(:last-child) {
  margin-bottom: 15px;
}
.infoSection__ul li {
  all: unset;
  display: block;
}
.infoSection__ul li:not(:last-child) {
  margin-bottom: 5px;
}
.infoSection__ul__inner li {
  display: list-item;
  list-style-type: "∙";
  padding-inline-start: 1ch;
}
.infoSection__ul__inner li:not(:last-child) {
  margin-bottom: 10px;
}

.infoSection.infoSection--certificates .infoSection__ul {
  border-radius: 4px;
}
.infoSection.infoSection--other .infoSection__ul {
  border-radius: 4px;
}

.infoSectionProjects {
  margin-bottom: 20px;
}

.infoSectionProjects__header {
  display: flex;
  align-items: center;
  color: #ff9500;
  text-shadow: 1px 1px 0px #000;
  font-family: silkscreenRegular;
}
.infoSectionProjects__header__icon {
  width: 50px;
  height: 50px;
  background-image: url('../src/images/light_bulb_icon.png');
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 5px;
}
.infoSectionProjects__body {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
  padding: 15px;
  border-radius: 4px;
  text-shadow: 0px 0px 2px #fff;
  background-color: #ffe8c9;
}
.infoSectionProjects__projectContainer {
  position: relative;
  max-width: 290px;
}
.infoSectionProjects__projectContainer__image {
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  height: 200px;
}
.infoSectionProjects__projectContainer__link {
  width: 60px;
  background-color: #009dff;
  padding: 3px;
  text-align: center;
  border-radius: 4px;
  margin-left: auto;
  color: #fff;
  text-shadow: none;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
}
.infoSectionProjects__projectContainer__link:active {
  transform: translateY(1px);
  box-shadow: none;
  filter: brightness(75%);
}
.infoSectionProjects__header {
  font-weight: bold;
  font-size: 30px;
}
.infoSectionProjects__projectContainer__title {
  font-weight: bold;
  color: #000;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.infoSectionProjects__projectContainer__images_container {
  position: relative;
  height: 250px;
  background-color: lightgoldenrodyellow;
  overflow: hidden;

}
.infoSectionProjects__projectContainer__images {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  transition: all 0.5s;
}
.infoSectionProjects__projectContainer__images img {
  height: 100%;
  min-width: 100%;
  object-fit: contain;
}
.infoSectionProjects__projectContainer .imageController {
  position: absolute;
  top: 0px;
  background-color: rgba(0,0,0,0.3);
  width: 24px;
  height: 100%;
  cursor: pointer;
}
.infoSectionProjects__projectContainer .imageController:hover {
  background-color: rgba(0,0,0,0.2);
}
.infoSectionProjects__projectContainer .imageController.right {
  right: 0px;
}
.infoSectionProjects__projectContainer .imageController.left::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right:10px solid white;
}
.infoSectionProjects__projectContainer .imageController.right::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid white;
}
.infoSectionProjects__projectContainer__desc__inner {
  height: 85px;
}

.experienceMediaTitle {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: bold;
}
.experienceMediaContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3) inset;
  margin-bottom: 15px;
}
.experienceMediaContainer__imageContainer:not(last-item) {
  margin-right: 10px;
}
.experienceMediaContainer__title {
  text-align: center;
  margin-bottom: 5px;
}
.experienceMediaContainer__image {
  width: 250px;
  min-width: 200px;
  height: auto;
}

.footer {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 25px;
  padding: 15px;
}
.footer .minigameBox.minigameBox--reward {
  position: absolute;
  right: 10px;
  bottom: 0px;
  padding: 0px;
  border-radius: 4px;
}
.footer__copyright {
  margin-left: 15px;
  z-index: 100;
}

.miningQuestContainer {
  overflow: scroll;
}
.miningQuestText {
  position: absolute;
  top: 50px;
  right: 50px;
  color: #fff;
  width: 300px;
}


/* Dark Mode */
.App.darkMode {
  background-color: #212121;
}
.App.darkMode .landing {
  background-image: linear-gradient(#000, 70%, #212121);
}
.App.darkMode .landing__bioContainer {
  background-color: #424242;
  color: #fff;
  text-shadow: 0px 0px 2px #000;
}
.App.darkMode .infoSectionProjects__header__text {
  color: #fff;
}
.App.darkMode .infoSection__body__title {
  color: #fff;
  text-shadow: 0px 0px 2px #000;
}
.App.darkMode .infoSection__body {
  background-color: #212121;
  color: #fff;
  text-shadow: 0px 0px 2px #000;
}
.App.darkMode .infoSectionProjects__body {
  background-color: #212121;
  color: #fff;
  text-shadow: 0px 0px 2px #000;
}
.App.darkMode .infoSectionProjects__projectContainer__title {
  color: #fff;
  text-shadow: 0px 0px 2px #000;
}
.App.darkMode .footer .minigameBox.minigameBox--reward {
  background-color: #212121;
}
.App.darkMode .footer .minigameBox--reward__slotMachine__cover {
  background-color: #212121;
}
.App.darkMode .footer .minigameBox.minigameBox--reward .minigameBox--reward__cost {
  color: #fff;
}
.App.darkMode .footer__copyright {
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .landing {
    height: 100%;
  }
  .landing__center {
    position: relative;
    top: 0px;
    left: 0px;
    transform: translate(0px);
  }
  .landing__games {
    flex-wrap: wrap;
  }
  .footer {
    height: 300px;
  }
  .footer__copyright {
    position: absolute;
    bottom: 15px;
    left: 0px;
    margin-left: 0px;
    font-size: 12px;
  }
  .miningQuestText {
    display: none;
  }
  .infoSectionProjects__projectContainer:not(:last-child) {
    margin-bottom: 15px;
  }
}